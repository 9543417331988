import React, { useEffect, useState } from "react";
import SimpleCrypto from "simple-crypto-js"; // decrypt userId

import "./App.css";
import Location from "./Components/Location";
import Instructions from "./Components/Instructions";
import GeoFence from "./Components/GeoFence.js";
import Loading from "./Components/Loading.js";
import Thankyou from "./Components/Thankyou.js";
import UploadInitialImages from "./Components/UploadInitialImages";

import {
  getGeoFencingStatus,
  getPropertyOwner,
} from "./modules/geofencing.module";

//TODO : add appropriate pageRoute on final push
const App = () => {
  //useStates
  const [pageRoute, setPageRoute] = useState(1); //! change to 6 to only view image upload
  const [propId, setPropId] = useState(null); //holds deciphered property_id
  const [redo, setRedo] = useState(false);
  const [userName, setUserName] = useState(null); //userName
  const [propertyName, setPropertyName] = useState(null); //propertyName
  const [addressProperty, setAddressProperty] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [success, setsuccess] = useState(null);

  //on loadup we decipher the encrypted property_id
  //! comment this useEffect to only view image upload
  useEffect(() => {
    document.title = "TechEye - World's first real estate surveillance app";
    try {
      if (window.location.pathname) {
        let propertyIdEncrypt = window.location.pathname.substring(1);
        const simpleCrypto = new SimpleCrypto(
          process.env.REACT_APP_SIMPLE_CRYPTO_ENCRYPT
        );
        const decipherText = simpleCrypto.decrypt(propertyIdEncrypt);
        console.log(decipherText);
        //propertyId stored here after decipher
        setPropId(decipherText.propertyId); //save decrypted UserId
        setRedo(decipherText.redo);
        return;
      } else {
        return;
      }
    } catch (e) {
      alert("Incorrect Link");
      setPageRoute(5); //switch to exit page
    }
  }, []);

  // check if decrypted property Id has its geofencing done
  useEffect(() => {
    if (propId) {
      getGeoFencingStatus(propId, (error, status) => {
        if (
          (status.data.geo_codes === null ||
            Object.keys(status.data.geo_codes).length === 0) &&
          status.data.kyc_status === "accepted" &&
          status.data.video_noc_status === "accepted" &&
          status.data.property_media.length === 0
        ) {
          setsuccess(true);
          return;
        } else if (
          (status.data.geo_codes === null ||
            Object.keys(status.data.geo_codes).length === 0) &&
          status.data.kyc_status === "accepted" &&
          status.data.video_noc_status === "accepted" &&
          status.data.property_media.length > 0
        ) {
          alert(
            "Pictures for the property have been already captured. Please proceed to capture geo-points."
          );
          setPageRoute(3);
          setsuccess(false);
          return;
        } else if (
          (status.data.geo_codes !== null ||
            Object.keys(status.data.geo_codes).length > 0) &&
          status.data.kyc_status === "accepted" &&
          status.data.video_noc_status === "accepted" &&
          status.data.property_media.length > 0 &&
          redo === true
        ) {
          alert(
            "As you are geofencing again, pictures for the property have been already captured. Please proceed to capture geo-points."
          );
          setPageRoute(3);
          setsuccess(false);
          return;
        } else {
          //geo fencing done
          alert(
            "Geofencing for this property already done or kyc/noc not approved yet."
          );
          setPageRoute(5);
          setsuccess(false);
          return;
        }
      });
    }
  }, [propId]);

  //show property details if belong.
  useEffect(() => {
    if (propId && success) {
      getPropertyOwner(propId, (error, propertyInfo) => {
        setUserName(propertyInfo.data.User.full_name);
        setPropertyName(propertyInfo.data.property_name);
        setAddressProperty(propertyInfo.data.address);
        setUserDetails({
          user_id: propertyInfo.data.User.id,
          email: propertyInfo.data.User.email,
        });
        setPageRoute(2);
        setsuccess(true);
      });
    }
  }, [propId, success]);

  switch (pageRoute) {
    case 1:
      return (
        <>
          <Loading />
        </>
      ); //loading
    case 2:
      return (
        <>
          <Location
            setPageRoute={setPageRoute}
            propertyName={propertyName}
            userName={userName}
            addressProperty={addressProperty}
          />{" "}
        </>
      ); //confirm location
    case 3:
      return (
        <>
          <Instructions setPageRoute={setPageRoute} />
        </>
      ); //how to geofence
    case 4:
      return (
        <>
          <GeoFence
            userName={userName}
            addressProperty={addressProperty}
            setPageRoute={setPageRoute}
            propId={propId}
          />
        </>
      ); //start geofencing
    case 6:
      return (
        <>
          <UploadInitialImages
            setPageRoute={setPageRoute}
            propId={propId}
            userDetails={userDetails}
          />
        </>
      ); //loading
    case 5:
      return (
        <>
          <Thankyou />
        </>
      );
    default:
      return (
        <>
          <Thankyou />
        </>
      );
  }

  /* 
      const [errorpage, setErrorpage] = useState(null); //errorpage

    const [geofencingStatus, setGeofencingStatus] = useState(null); //errorpage
    const [status, setStatus] = useState(null); //messages that are displayed during this process
    const [geoResult,setGeoResult] = useState(null);//center position




   
    //gets center of all the mentioned geo-locations.
    //add that as another geo point
    const finished =async()=>{
        try{
            let value=getCenterOfBounds(arrayObj)
            setGeoResult([value])
            setArrayObj([...arrayObj,{latitude:value.latitude,longitude:value.longitude}])
            setLat(value.latitude);
            setLng(value.longitude);

            try{
                postGeoCodes(propId,arrayObj,(error,status)=>{
                    if(status){
                        alert('thankyou for completing the geolocation')
                        setErrorpage(true)//this navigates to an error page

                    }else{
                alert('didnt upload geo points. please refresh and try after a while')
                    }
                })

            }catch(e){
                alert('didnt upload geo points. please refresh and try after a while')
            }

        }catch(e){
            alert('No points were captured')
        }
    }

    //variables
    const navigate = useNavigate();
    
 */

  /* 	return (
		<div className="App">
            {
     alertBox&&<Modal
      isOpen={alertBox}
      onRequestClose={()=>{setAlertBox(false)}}
      style={customStyles}
      contentLabel="Example Modal"
      appElement={document.getElementsByClassName('App')}
    >
        <div></div>
      <h2>Hi, GoodDay</h2>
      <div><b>property name:</b> {propertyName}</div>
      <div><b>owner name:</b> {userName}</div>
      <br/>
      <div><b>Is this the right location?</b></div>
      <br></br>
                <div className='modal-btn-container'>
                <button onClick={()=>{setAlertBox(false)}} className='btn-mdl'>Yes</button>
                 <button onClick={()=>{setAlertBox(false);setErrorpage(true)}}className='btn-mdl'>No</button>
                </div>
    </Modal>
            }
            {
            errorpage?navigate('/errorPage'):''
            }
			<button onClick={getLocation}>Get Location</button>
			<h1>Coordinates</h1>
            <p>{status}</p>
            <div className='scrollvalues'>
            {
                arrayObj.map((loc,index)=>{
                    return(<><p key={index}>latitude: {loc.latitude}</p><p>longitude: {loc.longitude}</p><br></br></>)
                })
            } 
            </div>    
            <button onClick={()=>finished()}>Finished</button>
		</div>
	); */
};

export default App;
