import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from './App';
import ErrorPage from './ErrorPage';

const Main = () => {
  return (
    <BrowserRouter>
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route path='/errorPage' element={<ErrorPage/>}/>
      <Route path='*' element={<App/>}/>
     
    </Routes>
    </BrowserRouter>
  );
}

export default Main;