import React from "react";
import "./Instructions.css";

function Instructions({ setPageRoute }) {
  return (
    <div className="Instruction-container">
      <div>
        <div className="Instruction-header">
          <img src="/img/eye.png" className="img-header" />
          <img src="/img/TechEye.png" className="" />
        </div>
        <div className="Instruction-body">
          <h3 className="Instruction-body-header clr-red">IMPORTANT:</h3>
          <h2 className="Instruction-body-header">
            How To Mark The Property Correctly
          </h2>
          <ol className="Instruction-body-ol">
            <li className="Instruction-body-li">
              <b>Step - 1</b>
              <br />
              <span className="Instruction-body-body">
                Before you proceed, identify a minimum of 6 points which will be
                used to mark the property.
              </span>
            </li>
            <li className="Instruction-body-li">
              <div className="Instruction-body-img">
                <img src="/img/area.png" className="Instruction-body-img-img" />
              </div>
            </li>
            <li className="Instruction-body-li">
              <b>Step - 2</b>
              <br />
              <span className="Instruction-body-body">
                Click on “START GEOFENCING” button at the bottom of this page.
              </span>
            </li>
            <li className="Instruction-body-li">
              <b>Step - 3</b>
              <br />
              <span className="Instruction-body-body">
                Stand on the identified Point 1 and click on the button right to
                the Point 1 in the next page 3 times. When you click the numbers
                it will turn dark indicating click was successful.{" "}
              </span>
            </li>
            <li className="Instruction-body-li">
              <b>Step - 4</b>
              <br />
              <span className="Instruction-body-body">
                Please keep a gap of atleast 3 seconds between consecutive
                clicks for the device to capture accurate coordinates.
              </span>
            </li>
            <li className="Instruction-body-li">
              <b>Step - 5</b>
              <br />
              <span className="Instruction-body-body">
                Move to the identified Point 2 and follow the same process as
                above.
              </span>
            </li>

            <li className="Instruction-body-li">
              <b>Step - 6</b>
              <br />
              <span className="Instruction-body-body">
                Carry out the same process for the rest of the 4 points out of
                minimum 6 points.
              </span>
            </li>

            <li className="Instruction-body-li">
              <b>Step - 7</b>
              <br />
              <span className="Instruction-body-body">
                As soon as you will finish clicking for the 6th point, a plus
                button will be visible at the bottom, clicking on which you can
                add a new point, if needed.
              </span>
            </li>

            <li className="Instruction-body-li">
              <b>Step - 8</b>
              <br />
              <span className="Instruction-body-body">
                Once all the points are clicked, you can finally submit the data
                by clicking the Finish Geofencing button at the bottom. You
                should receive a successful message indicating the geofencing is
                completed.
              </span>
            </li>

            <li className="Instruction-body-li">
              <b>Step - 9</b>
              <br />
              <span className="Instruction-body-body">
                After that please upload atleast 4 pictures of the property
                following given instructions on the page.
              </span>
            </li>

            <li className="Instruction-body-li">
              <b>Note -</b>
              <br />
              <span className="Instruction-body-body">
                If you encounter any issues at any point, please feel free to
                contact us by clicking the "Help" button located in the top
                right corner of the respective page.
              </span>
            </li>
          </ol>
        </div>
      </div>
      <div>
        <button
          className="button-start"
          onClick={() => {
            setPageRoute(4);
          }}
        >
          start geofencing
        </button>
      </div>
    </div>
  );
}

export default Instructions;
