import React from 'react'
import './errorpage.css'

function ErrorPage() {
  return (
      <div className='error-page'>
    <h1>Thankyou for visiting</h1>
      </div>
  )
}

export default ErrorPage