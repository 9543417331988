import React, { useEffect, useState } from "react";
import "./GeoFence.css";
import wp from "../wp.png";

import { postGeoCodes } from "../modules/geofencing.module";
import Loading from "./Loading";

function GeoFence({ addressProperty, userName, setPageRoute, propId }) {
  const [lat, setLat] = useState(null); //latitude
  const [lng, setLng] = useState(null); //longitude
  const [url, setUrl] = useState(null); //set url to show map
  const [block, setBlock] = useState(null);
  const [isLoading, setIsLoading] = useState(true); //if true show loading screen
  const [point, setPoint] = useState(0);
  const [addPointVal, setAddedPointVal] = useState(3);
  const [arrayObj, setArrayObj] = useState([]); //array of geo positions
  const [setVal, setSetVal] = useState([]); //array of points value added [extra],index
  const [index, setIndex] = useState(1); //counter of point number
  const [value, setValue] = useState(6);
  const [indexFlow, setindexFlow] = useState(1);
  useEffect(() => {
    const getLocation = () => {
      if (!navigator.geolocation) {
        alert("Geolocation is not supported by your browser");
        setBlock(true);
      } else {
        setIsLoading(true);
        function getGeoCord() {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUrl(
                `https://maps.google.com/maps?q=${position.coords.latitude},${position.coords.longitude}&hl=en&z=17&amp;&output=embed`
              );

              //lat. and long. are saved in the below setArrayObj
            },
            () => {
              alert("Unable to retrieve your location");
            },
            {
              enableHighAccuracy: true,
            }
          );
          setIsLoading(false);
        }
        //once called saves geo-cords in setArrayObj
        getGeoCord();
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    console.log(lat, lng, "coordinates");
    if (lat && lng) {
      setUrl(
        `https://maps.google.com/maps?q=${lat},${lng}&hl=en&z=17&amp;&output=embed`
      );
    }
  }, [lat, lng]);

  //called only on last point
  const addIndex = () => {
    console.log("adding index", index + 1);
    setIndex(index + 1);
  };
  const empty = () => {
    return;
  };

  //saves location-cord of lat. long. in setArrayObj
  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      setIsLoading(true);
      function getGeoCord() {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            //lat. and long. are saved in the below setArrayObj
            setArrayObj([
              ...arrayObj,
              {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            ]);
            setIsLoading(false);
          },
          () => {
            alert("Unable to retrieve your location");
          },
          {
            enableHighAccuracy: true,
          }
        );
      }
      //once called saves geo-cords in setArrayObj
      getGeoCord();
    }
  };

  //point in incremented to show which button you are on
  const incrementPoint = () => {
    setPoint(point + 1);

    //if last point.
    if ((point + 1) % 3 == 0) {
      //geolocation added to array
      getLocation();
      //increases index by 1 i.e POINT "N" :
      addIndex();
    }
  };

  //submit geocodes
  const submitGeoCodes = () => {
    try {
      if (arrayObj.length > 4) {
        postGeoCodes(propId, arrayObj, (error, status) => {
          if (error) {
            alert("Did not upload geo points. please refresh and try again");
          } else {
            alert("Geofencing for this given property has been completed.");
            setPageRoute(5); //Thank You Page
          }
        });
      } else {
        alert("You should capture a minimum of 6 Geo-Location points.");
      }
    } catch (e) {
      console.log(e);
      alert("Did not upload geo points. please refresh and try after a while.");
    }
  };

  return (
    <div className="GeoFence-container">
      <div className="GeoFence-header">
        TECH<span className="cyan">EYE</span>
      </div>
      <a href="whatsapp://send?phone=+917205046996">
        <div class="helpDiv1">
          <img class="wpImage" src={wp} />
          <span class="helpText">help</span>
        </div>
      </a>
      <div className="geo-body-container">
        <div className="goe-bdy">
          <div className="GeoFence-body">
            <h2>{userName}</h2>
            <p>{addressProperty}</p>
            {url && (
              <iframe
                width="100%"
                height="170"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src={url}
              ></iframe>
            )}
            <p>
              <b>NB.</b> You need to click 3 times to mark a point. Please keep
              a gap of atleast 3 seconds between consecutive clicks for the
              device to capture accurate coordinates.
            </p>
            <h3>Capture Points</h3>

            <div className="flex-row">
              Point 1 :
              <div
                className={point >= 1 ? "box-btn box-btn-active" : "box-btn"}
              >
                1
              </div>
              <div
                className={point >= 2 ? "box-btn box-btn-active" : "box-btn"}
              >
                2
              </div>
              <div
                className={point >= 3 ? "box-btn box-btn-active" : "box-btn"}
              >
                3
              </div>
              <button
                className="btn-geofence-style"
                onClick={point >= 3 ? empty : incrementPoint}
              >
                Click
              </button>
            </div>

            <div className="flex-row">
              Point 2 :
              <div
                className={point >= 4 ? "box-btn box-btn-active" : "box-btn"}
              >
                1
              </div>
              <div
                className={point >= 5 ? "box-btn box-btn-active" : "box-btn"}
              >
                2
              </div>
              <div
                className={point >= 6 ? "box-btn box-btn-active" : "box-btn"}
              >
                3
              </div>
              <button
                className="btn-geofence-style"
                onClick={point >= 6 ? empty : incrementPoint}
                disabled={point >= 3 ? "" : "true"}
              >
                Click
              </button>
            </div>
            <div className="flex-row">
              Point 3 :
              <div
                className={point >= 7 ? "box-btn box-btn-active" : "box-btn"}
              >
                1
              </div>
              <div
                className={point >= 8 ? "box-btn box-btn-active" : "box-btn"}
              >
                2
              </div>
              <div
                className={point >= 9 ? "box-btn box-btn-active" : "box-btn"}
              >
                3
              </div>
              <button
                className="btn-geofence-style"
                onClick={point >= 9 ? empty : incrementPoint}
                disabled={point >= 6 ? "" : "true"}
              >
                Click
              </button>
            </div>
            <div className="flex-row">
              Point 4 :
              <div
                className={point >= 10 ? "box-btn box-btn-active" : "box-btn"}
              >
                1
              </div>
              <div
                className={point >= 11 ? "box-btn box-btn-active" : "box-btn"}
              >
                2
              </div>
              <div
                className={point >= 12 ? "box-btn box-btn-active" : "box-btn"}
              >
                3
              </div>
              <button
                className="btn-geofence-style"
                onClick={point >= 12 ? empty : incrementPoint}
                disabled={point >= 9 ? "" : "true"}
              >
                Click
              </button>
            </div>
            <div className="flex-row">
              Point 5 :
              <div
                className={point >= 13 ? "box-btn box-btn-active" : "box-btn"}
              >
                1
              </div>
              <div
                className={point >= 14 ? "box-btn box-btn-active" : "box-btn"}
              >
                2
              </div>
              <div
                className={point >= 15 ? "box-btn box-btn-active" : "box-btn"}
              >
                3
              </div>
              <button
                className="btn-geofence-style"
                onClick={point >= 15 ? empty : incrementPoint}
                disabled={point >= 12 ? "" : "true"}
              >
                Click
              </button>
            </div>

            <div className="flex-row">
              Point 6 :
              <div
                className={point >= 16 ? "box-btn box-btn-active" : "box-btn"}
              >
                1
              </div>
              <div
                className={point >= 17 ? "box-btn box-btn-active" : "box-btn"}
              >
                2
              </div>
              <div
                className={point >= 18 ? "box-btn box-btn-active" : "box-btn"}
              >
                3
              </div>
              <button
                className="btn-geofence-style"
                onClick={point >= 18 ? empty : incrementPoint}
                disabled={point >= 15 ? "" : "true"}
              >
                Click
              </button>
            </div>

            {value >= 7 ? (
              <div className="flex-row">
                Point 7 :
                <div
                  className={point >= 19 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 20 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 21 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 21 ? empty : incrementPoint}
                  disabled={point >= 18 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {value >= 8 ? (
              <div className="flex-row">
                Point 8 :
                <div
                  className={point >= 22 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 23 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 24 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 24 ? empty : incrementPoint}
                  disabled={point >= 21 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {
              ////
            }
            {value >= 9 ? (
              <div className="flex-row">
                Point 9 :
                <div
                  className={point >= 25 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 26 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 27 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 27 ? empty : incrementPoint}
                  disabled={point >= 24 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {value >= 10 ? (
              <div className="flex-row">
                Point 10 :
                <div
                  className={point >= 28 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 29 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 30 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 30 ? empty : incrementPoint}
                  disabled={point >= 27 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {value >= 11 ? (
              <div className="flex-row">
                Point 11 :
                <div
                  className={point >= 31 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 32 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 33 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 33 ? empty : incrementPoint}
                  disabled={point >= 30 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {value >= 12 ? (
              <div className="flex-row">
                Point 12 :
                <div
                  className={point >= 34 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 35 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 36 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 36 ? empty : incrementPoint}
                  disabled={point >= 33 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {value >= 13 ? (
              <div className="flex-row">
                Point 13 :
                <div
                  className={point >= 37 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 38 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 39 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 39 ? empty : incrementPoint}
                  disabled={point >= 36 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}
            {value >= 14 ? (
              <div className="flex-row">
                Point 14 :
                <div
                  className={point >= 40 ? "box-btn box-btn-active" : "box-btn"}
                >
                  1
                </div>
                <div
                  className={point >= 41 ? "box-btn box-btn-active" : "box-btn"}
                >
                  2
                </div>
                <div
                  className={point >= 42 ? "box-btn box-btn-active" : "box-btn"}
                >
                  3
                </div>
                <button
                  className="btn-geofence-style"
                  onClick={point >= 42 ? empty : incrementPoint}
                  disabled={point >= 39 ? "" : "true"}
                >
                  Click
                </button>
              </div>
            ) : (
              ""
            )}

            {point > 17 && value <= 13 ? (
              <div className="optional-add">
                <div
                  className="plus-btn"
                  onClick={
                    point >= 18 && point % 3 == 0
                      ? () => setValue(value + 1)
                      : ""
                  }
                >
                  +
                </div>{" "}
                ADD A NEW POINT TO CAPTURE (Optional)
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="submit-geo" onClick={submitGeoCodes}>
          Finish geofencing
        </div>
      </div>
    </div>
  );
}

export default GeoFence;
