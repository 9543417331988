import React, { useState } from "react";
import Modal from "react-modal"; //modal
import "./Location.css";

function Location({ setPageRoute, propertyName, userName, addressProperty }) {
  const [alertBox, setAlertBox] = useState(true); //alertBox

  return (
    <div className="modal-container">
      <Modal
        isOpen={alertBox}
        onRequestClose={() => {
          setAlertBox(false);
        }}
        contentLabel="Example Modal"
        appElement={document.getElementsByClassName("App")}
        className="modal"
      >
        <div className="modal-header">
          TECH<span className="cyan">EYE</span>
        </div>
        <div className="modal-body">
          <h2 className="modal-body-header">Hi, Good Day!</h2>
          <p className="modal-body-content">
            Please confirm that you are doing geofencing for -
          </p>
          <div className="capatalize">
            <b>Property Name - </b> {propertyName}
          </div>
          <div className="capatalize">
            <b>Owner's Name - </b> {userName}
          </div>
          <div className="capatalize">
            <b>Address - </b>
            <br></br> {addressProperty}
          </div>
          <br />
        </div>
        <div className="modal-btn-container">
          <button
            onClick={() => {
              setAlertBox(false);
              setPageRoute(5);
            }}
            className="btn-mdl btn-mdl-confirm"
          >
            cancel
          </button>
          <button
            onClick={() => {
              setAlertBox(false);
              setPageRoute(6);
            }}
            className="btn-mdl btn-mdl-cancel"
          >
            confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Location;
