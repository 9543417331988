import React from 'react'
import "./Thankyou.css"

function Thankyou() {
  return (
    <div className='centerPosition'>
    <h2>Thank You for visiting.</h2>
    </div>
  )
}

export default Thankyou