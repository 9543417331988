import React from 'react'
import './Loading.css'

function Loading() {
    return (
      <div className='body'>
        Loading...
      </div>
      );
    
}

export default Loading